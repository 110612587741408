<template>
  <div class="wrapper">
    <div class="video-wrapper" :class="{ 'w-video': session && !getDisabled }">
      <div class="innerVideo" id="videochat"></div>
    </div>
    <div class="whole" :class="{ 'w-video': session && !getDisabled }">
        <div class="chat">
            <div class="chat-wrapper" ref="wrapper" @scroll="handleScroll">
                <div class="chats-tab" v-if="internalChat || supportChats.length && !type.length">
                    <div class="main" :class="{active: !activeSupport}" @click="activeSupport = undefined">
                        <span class="no-img">M</span>
                        <span>Main Chat</span>
                    </div>
                    <hr>
                    <template v-for="(chat, i) in supportChats" :key="i">
                        <div class="support" :class="{active: activeSupport === chat._id}" @click="activeSupport = chat._id">
                            <span class="no-img">{{chat.users.filter(el => el.userId !== chat.creator)[0].userInfo.name.charAt(0)}}</span>
                            <span>{{chat.users.filter(el => el.userId !== chat.creator)[0].userInfo.name.split(' ')[0]}}</span>
                        </div>
                    </template>
                    <template v-if="internalChat && canCreate.includes(getUserProfile.role.id)">
                        <div class="support" :class="{active: activeSupport === internalChat._id}" @click="activeSupport = internalChat._id">
                            <span class="no-img">I</span>
                            <span>Internal</span>
                        </div>
                    </template>
                </div>
                <details-info :ticket="productDetails" />
                <template v-if="chatLoaded">
                <template
                    v-for="(chat, chat_index) in chatDetails.chatDetails"
                    :key="chat_index"
                >
                    <Messages :chat="chat" />
                </template>
                <template v-for="(chat, chat_index) in newMessages" :key="chat_index">
                    <Messages :chat="chat" />
                </template>
                </template>
            </div>
            <template v-if="chatLoaded">
                <Chat v-if="canChat" :roomId="chatDetails.room" @sentMessage="handleFirstMessage"/>
            </template>
            <template v-else>
                <div class="loading">
                <span class="loader"></span>
                </div>
            </template>
        </div>
        <transition name="slide">
            <chat-extras
                v-if="edit"
                :info="details"
                :ticket="true"
                :room="chatDetails.room"
                :canEdit="canCreate.includes(getUserProfile.role.id)"
                :type="type"
                @searchkey="handleSearch"
                @supportChat="support = true"
                @add="addUsers = true"
                @editTicket="editTicketName = true"
            />
        </transition>
    </div>
    <edit-modal
        :title="`Edit ticket Name`"
        :items="toEditName"
        v-if="editTicketName"
        @close="editTicketName = false"
        @submitData="editTicketData"
    />
    <users-to-chat
      v-if="support"
      @close="support = false"
      @data="handleSupportChat"
    />
    <users-to-chat
      v-if="addUsers"
      @close="addUsers = false"
      @data="handleAddUsers"
    />
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axiosInstance from '@/services/AxiosTokenInstance'
import nodeInstance from "@/services/NodeServiceinstance";
import {
  CHAT_ROOM_USERS_LIST,
  GET_CHATS_LIST,
  UPLOAD_CHAT_ATTACHMENT,
  GET_USER_PROFILE_GETTER
} from "@/store/storeconstants";
import ChatExtras from "@/components/Ui/Chat/ChatExtras.vue";
import DetailsInfo from "@/components/Chat/Details.vue";
import UsersToChat from "@/components/Ui/Chat/UsersToChat.vue";
import Chat from "@/components/Chat/Chat.vue";
import Messages from "@/components/Chat/Messages.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
    edit: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    type: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
        internalInstance: getCurrentInstance(),
        currentChatPage: 1,
        chatLoaded: false,
        chatDetails: {},
        newMessages: [],
        currentScroll: 0,
        showVid: false,
        user: localStorage.getItem("U_P")
            ? JSON.parse(localStorage.getItem("U_P"))
            : {},
        session: false,
        usersInTicket: [],
        imgURL: process.env.VUE_APP_DO_SPACES,
        canChat: false,
        editTicketName: false,
        support: false,
        addUsers: false,
        org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        supportChats: [],
        canCreate: [4, 5, 6, 7, 8],
        activeSupport: undefined,
        internalChat: undefined
    };
  },

  components: {
    DetailsInfo,
    Chat,
    Messages,
    ChatExtras,
    EditModal,
    UsersToChat
  },

  computed: {
    productDetails() {
      return {
        product: {
          name: this.details.product?.name,
          serial_no: this.details.product?.serial_no,
          warranty_end_date: this.details.product?.warranty_end_date,
          client: {
            name: this.details.client?.name,
          },
          ref: this.details.product?.model,
        },
        project: this.details?.site,
        description: this.details?.description,
        image: this.details.product
          ? this.details.product.product_images.length
            ? this.imgURL + this.details.product.product_images[0].image
            : this.details.client.logo
            ? this.imgURL + this.details.client.logo
            : null
          : null
      };
    },

    toEditName() {
        return [
            {
                type: "text",
                label: "Ticket name",
                value: this.details.title,
            }
        ];
    },

    ...mapGetters({
      getCallStatus: "call/getCallStatus",
      getCallId: "call/getCallId",
      getDisabled: "call/getDisabled",
    }),
    
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
  },

  watch: {
    getCallId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        }
        if (val && val === this.chatDetails.room) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },

    details: {
      immediate: true,
      handler() {
        this.currentScroll = 0;
        this.chatLoaded = false;
        this.currentChatPage = 1;
        this.getChatData();
      },
    },

    currentChatPage() {
      this.getChatData();
    },

    chatDetails: {
      deep: true,
      handler(val) {
        this.$emit('closeEdit') 
        // this.readMessage();
        if (!this.getCallId) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        } else if (this.getCallId && val.room === this.getCallId) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },

    usersInTicket: {
      immediate: true,
      handler(val) {
        if (val.includes(this.user?.id)) this.canChat = true;
      },
    },

    activeSupport() {
        this.getChatData()
    }
  },

  methods: {
    ...mapActions("chat", {
      fetchChatList: GET_CHATS_LIST,
      updateChatFile: UPLOAD_CHAT_ATTACHMENT,
      fetchChatUsers: CHAT_ROOM_USERS_LIST,
    }),

    ...mapMutations({
      setDisabled: "call/setDisabled",
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
    }),

    async getChatData(key) {
        if (this.details.id) {
            this.newMessages = [];
            this.chatLoaded = false;
            let chatParams

            if (this.type.length) {
                chatParams = {
                    page_id: this.currentChatPage,
                    room_id: this.type[1],
                };
            }
            else if(!this.activeSupport) {
                chatParams = {
                    page_id: this.currentChatPage,
                    ticket_id: this.details.id,
                };
            }
            else {
                chatParams = {
                    page_id: this.currentChatPage,
                    room_id: this.activeSupport,
                };
            }
            

            if (key) chatParams.search_data = key;

            const org = JSON.parse(localStorage.getItem("organization"));

            await this.fetchChatList(chatParams).then((response) => {
            this.totalChats = response.data.totalChatCount;
            this.chatLoaded = true;
            const wrapper = this.$refs.wrapper;
            
            if(!response.data.room) {
                this.emitter.emit("create-room", {
                type: "ticket",
                ticketId: this.details.id,
                organizationId: org.id,
                });
                location.reload()
            }

            if (response && this.currentChatPage === 1) {
                this.chatDetails = response.data;
                this.chatDetails.chatDetails.reverse();
                setTimeout(() => {
                wrapper.scrollTop = wrapper.scrollHeight;
                }, 200);
            } else if (response && this.currentChatPage !== 1) {
                let newChats = response.data.chatDetails.reverse();
                this.chatDetails.chatDetails.unshift(...newChats);
                setTimeout(() => {
                wrapper.scrollTop = wrapper.scrollHeight - this.currentScroll;
                }, 1);
            }
            });

            if (this.canCreate.includes(this.getUserProfile.role.id) && !this.type.length) {
                this.getSupportChats()
                this.getInternalChats()
            }

            this.fillUsers();

            const userParams = {
            room_id: this.chatDetails.room,
            };

            await this.fetchChatUsers(userParams).then((data) => {
            const isInTicket = this.usersInTicket.includes(this.user.id);
            const isJoined = data.data.users.filter(
                (el) => el.userId === this.user.id
            );

            if (isInTicket && !isJoined.length) {
                this.joinRoom();
            }
            });
        }
    },

    async getSupportChats() {
        const response = await nodeInstance.get(`/chats/supportUsers?organizationId=${this.org.id}&ticketId=${this.details.id}`)
        this.supportChats = response.data.roomDetails
    },
    
    async getInternalChats() {
        const response = await nodeInstance.get(`/chats/roomDetails/${this.chatDetails.room}`)
        this.internalChat = response.data.internalRoom
    },

    handleSupportChat(data) {
      const params = {
        type: "support",
        ticketId: this.details.id,
        organizationId: this.org.id,
        participants: data.map((el) => el.id),
      };

      this.emitter.emit("create-room", params);
      setTimeout(() => {
        this.getSupportChats();
        this.getInternalChats();
      }, 200);
    },
    
    handleAddUsers(data) {
      const params = {
        roomId: this.chatDetails.room,
        participantIds: data.map((el) => el.id),
      };
      
      this.emitter.emit("add-participants-to-room", params);
    },

    handleSearch(data) {
      this.currentChatPage = 1;
      this.getChatData(data);
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0 && this.currentChatPage < this.totalChats) {
        this.currentScroll = e.target.scrollHeight;
        this.currentChatPage += 1;
      }
    },

    async editTicketData(data) {

		let toSend = {
			org_id: this.org.id,
			title: data["Ticket name"]
		};

		const response = await axiosInstance.post(
			`ticket/${this.details.id}`,
			toSend
		);

		if (response.status === 200) location.reload();
	},

    startCall() {
      let userToSend = this.user;
      userToSend.type = "single";
      const params = {
        roomId: this.chatDetails.room,
        with: userToSend,
      };
      this.setCallStatus(true);
      this.setCallId(this.chatDetails.room);
      this.emitter.emit("request-to-join-call", params);
      this.emitter.emit("video-section-prepared", params);
    },

    fillUsers() {
      this.usersInTicket = [];
      this.usersInTicket.push(this.details.ticket_raised.id);
      if (this.user.role.id === 4) this.usersInTicket.push(this.user.id)
      if (this.details.assigned_users.length) {
        const assignees = this.details.assigned_users.map((el) => el.id);
        this.usersInTicket.push(...assignees);
      }
    },

    joinRoom() {
      const params = {
        ticketId: this.details.id,
      };
      this.emitter.emit("join-room", params);
    },

    readMessage() {
      let unreadMsg = [];
      this.chatDetails.chatDetails.forEach((element) => {
        const receivers = element.receivers
          .filter((el) => !el.isRead)
          .map((el) => el.userId);
        if (receivers.length) {
          if (receivers.includes(this.user.id)) unreadMsg.push(element._id);
        }
      });
      const data = {
        room: this.chatDetails.room,
        chatIds: unreadMsg,
      };
      this.emitter.emit("read-message", data);
    },

    async handleFirstMessage() {
      const assignedUsers = this.details.assigned_users.map(el => el.id)
      const sentByAssigned = this.chatDetails.chatDetails.filter(el => assignedUsers.includes(el.sentBy.userId)).length + this.newMessages.filter(el => assignedUsers.includes(el.sentBy.userId)).length


      if (!sentByAssigned && assignedUsers.includes(this.user.id)) {
        const toSend = {
          ticket_id: this.details.id,
          status_id: 3
        }

        await axiosInstance.post('change-ticket-status', toSend)
      }
    }
  },

  created() {
    this.emitter.on("new-message", (data) => {
      if (data.room._id === this.chatDetails.room) {
        let newArr = [
          {
            id: data._id,
            attachmentUrls: data.attachmentUrls,
            createdAt: data.createdAt,
            media: data.media,
            receivers: data.receivers,
            room: data.room,
            sentBy: data.sentBy,
            status: data.status,
            text: data.text,
            type: data.type,
          },
        ];
        this.newMessages.push(...newArr);
        if (data.type === "rejectCall") this.emitter.emit("destroy-call");

        setTimeout(() => {
          const wrapper = this.$refs.wrapper;
          if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
        }, 200);
      }
    });

    this.emitter.on("chats-deleted", (data) => {
      if (data.room === this.chatDetails.room) {
        this.newMessages = [];
        this.chatDetails.chatDetails = [];
        this.chatDetails.totalChatCount = 0;
      }
    });

    this.emitter.on("user-typing", (data) => {
      if (
        data.room === this.chatDetails.room &&
        data.userDetails.userId !== this.user.id
      ) {
        this.userIsTyping = true;
        const wrapper = this.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

        clearTimeout(timer);
        const timer = setTimeout(() => {
          this.userIsTyping = false;
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}

.wrapper {
  padding: 20px 0 0 20px;
  position: relative;
  height: calc(100% - 4rem);
  display: flex;
  .video-wrapper {
    width: 0px;
    &.w-video {
      width: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
    }
    .innerVideo {
      width: 100%;
      height: 100%;
    }
  }
  .whole {
    display: flex;

    

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    &.w-video {
      width: 50%;
      padding: 0 0 0 20px;
      .chat-wrapper {
        .details-wrapper {
          width: 100%;
        }
      }
    }

    .chat {
        display: flex;
        flex-direction: column;

        

        @media only screen and (max-width: 1200px) {
            width: 100%;
        }

        .chat-wrapper {
            height: 95%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 0 20px 20px 0;
            scroll-behavior: smooth;
            transition: 0.2s;
            position: relative;

            @media only screen and (max-width: 1200px) {
                width: 100%;
            }

            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #f2f5f8;
                border: 2px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                border-radius: 9999px;
                transition: 0.2s;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #e6e8eb;
                border: 2px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                border-radius: 9999px;
            }

            .chats-tab {
                position: sticky;
                top: 0;
                width: 100%;
                background: white;
                display: flex;
                align-items: center;
                gap: 2rem;
                padding-bottom: 10px;
                border-bottom: solid 1px $grey;

                @media only screen and (max-width: 1200px) {
                    width: 100%
                }

                hr {
                    border: none;
                    height: 100%;
                    width: 1px;
                    background: $grey;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.2rem;
                    user-select: none;
                    cursor: pointer;

                    &.active {
                        .no-img {
                            border: solid 2px $grey;
                        }
                    }

                    .no-img {
                        width: 40px;
                        height: 40px;
                        background: var(--primary-color);
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        border: solid 2px transparent;
                        transition: 0.2s;
                    }
                }
            }
        }
        .chat-bar {
            height: 5%;
        }
    } 
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    width: 0px;
    overflow: hidden;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 50%;
    overflow: hidden;
  }
}
</style>
<template>
    <div class="details-container">
        <main>
            <div class="title">{{details.title}}</div>
            <div class="info">
                <div class="item">
                    <div class="subtitle">Device</div>
                    <div class="text">{{details.product.name}}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Serial</div>
                    <div class="text">{{details.product.serial_no}}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Clients</div>
                    <div class="text" v-if="details.site && details.site.client">
                        <span>{{details.site.client.client_details?.name}}</span>
                    </div>
                    <div class="text" v-else>undefined</div>
                </div>
            </div>
        </main>
        <div class="description">
            <div class="subtitle">Description</div>
            <div class="text">{{details.description}}</div>
        </div>
        <div class="questions" v-if="details.ticket_question_data.length">
            <div class="subtitle">Ticket Questions</div>
            <div class="text">
                <template v-for="(qt, n) in details.ticket_question_data[0].ticket_questions" :key="n">
                    <div class="question">
                        <span>Q{{n+1}}: {{qt.question.question}}</span>
                        <div>
                            <span>A{{n+1}}: </span>
                            <template v-for="(ans, x) in qt.answers" :key="x">
                                {{ans.answer_details.answer}}
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.details-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    main{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title{
            font-weight: bold;
            font-size: 1.3rem;
        }

        .info{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .item{
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
        }
    }
    .text {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .description{
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
}

</style>